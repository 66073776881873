$color-alert: #fc3737;
$color-blue-text: #0b0656;
$color-primary-gradient: linear-gradient(88.94deg, #0092E4 -0.47%, #00C9FF 100%);
$color-card-membership: linear-gradient(88.94deg, #D43B1F -0.47%, #FF8111 100%);

$color-primary: #0092E4;
$color-secondary:#FF9040;
$color-tertiary: #D43B1F;
$button-disabled: #bcbcbc;
$button-success: #5fd997;
$button-warning: #ffcf61;
$button-danger: #ff475d;
